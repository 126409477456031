import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Register.css';
import './Keyframes.css';
import './Responsiveness.css';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    dateOfBirth: '',
  });

  const [errors, setErrors] = useState({});
  const [focusedField, setFocusedField] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const validateField = (name, value) => {
    let error = '';
    const regex = {
      username: /^[a-zA-Z0-9_]{5,15}$/,
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    };
    switch (name) {
      case 'username':
        if (!regex.username.test(value)) error = 'Username must be 5-15 characters and contain only letters, numbers, and underscores.';
        break;
      case 'email':
        if (!regex.email.test(value)) error = 'Invalid email format.';
        break;
      case 'password':
        if (!regex.password.test(value)) error = 'Password must be 8+ characters, with uppercase, lowercase, number, and special character.';
        break;
      case 'confirmPassword':
        if (value !== formData.password) error = 'Passwords do not match.';
        break;
      case 'dateOfBirth':
        const today = new Date();
        const birthDate = new Date(value);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age < 18) error = 'You must be at least 18 years old.';
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: validateField(name, value) });
    e.target.classList.toggle('register-input-error', !!errors[name]);
  };

  const handleFocus = (fieldName) => setFocusedField(fieldName);
  const handleBlur = () => setFocusedField('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) formErrors[key] = error;
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      alert('Please fix the errors in the form.');
      return;
    }

    fetch('http://localhost:5000/auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.message === 'User registered successfully') {
        navigate('/');
      } else {
        setErrors({ form: data.message || 'Registration failed' });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      setErrors({ form: 'Registration failed. Please try again.' });
    });
  };

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={handleSubmit}>
        <h1 className="register-title">Create Account</h1>
        {['username', 'email', 'password', 'confirmPassword', 'dateOfBirth'].map((field) => (
          <div className="register-input-group" key={field}>
            <label htmlFor={field}>
              {field === 'confirmPassword' ? 'Confirm Password' : field === 'dateOfBirth' ? 'Date of Birth' : field.charAt(0).toUpperCase() + field.slice(1)}
            </label>
            <input
              type={
                field === 'password' || field === 'confirmPassword' ? 'password' :
                field === 'email' ? 'email' :
                field === 'dateOfBirth' ? 'date' :
                'text'
              }
              id={field}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              onFocus={() => handleFocus(field)}
              onBlur={handleBlur}
              className={`input-slide-in ${errors[field] ? 'register-input-error' : ''}`}
              required
            />
            {errors[field] && (
              <span className={`register-error-message ${focusedField === field ? 'show' : ''}`}>
                {errors[field]}
              </span>
            )}
          </div>
        ))}
        <button type="submit" className="register-btn">Register</button>
        
        {errors.form && <div className="error-message form-error">{errors.form}</div>}
        
        <div className="login-link-container">
          <span className="login-text">Already have an account? </span>
          <Link to="/login" className="login-link">Login now!</Link>
        </div>
      </form>
    </div>
  );
};

export default Register;
