import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import './Home.css';
import './Responsiveness.css';
import './Keyframes.css';

function Home() {
  const [showGames] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in
    const token = localStorage.getItem('authToken');
    setIsLoggedIn(!!token); // If a token exists, user is logged in
  }, []);

  return (
    <div className="home-container">
      {showGames && (
        <div className="home-games-section">
          {/* Large Tile (no hover) */}
          <div className="home-large-tile">
            <img src="/assets/banner.webp" alt="Large Tile" className="home-game-tile" />
            <div className="home-large-tile-overlay">
              <img src="/assets/logo.webp" alt="Logo" className="home-logo" />
              <p className="home-description">Experience the first-ever Cops and Robbers Casino gaming platform!</p>
              {isLoggedIn ? (
                <button
  className="big-tile-button discord-button"
  onClick={() => window.open("https://discord.com/YOUR_DISCORD_LINK", "_blank")}
>
  <FontAwesomeIcon icon={faDiscord} style={{ color: "rgb(88, 101, 242)" }} className="button-icon" />
  Join our Discord
</button>

              ) : (
<button className="big-tile-button" onClick={() => navigate("/register")}>
  <FontAwesomeIcon icon={faUserPlus} className="button-icon" />
  Register now
</button>

              )}
            </div>
          </div>

          <div className="home-game-tile-container" onClick={() => navigate('/limbo')}>
            <span className="home-new-badge">New!</span>
            <img src="/assets/limbo.webp" alt="Limbo" className="home-game-tile" />
          </div>

          <div className="home-game-tile-container" onClick={() => navigate('/coinflip')}>
            <span className="home-new-badge">New!</span>
            <img src="/assets/coinflip.webp" alt="Coinflip" className="home-game-tile" />
          </div>

          {[
            { src: "/assets/mines.webp", alt: "Mines" },
            { src: "/assets/crash.webp", alt: "Crash" },
            { src: "/assets/roulette.webp", alt: "Roulette" },
            { src: "/assets/blackjack.webp", alt: "Blackjack" },
            { src: "/assets/towers.webp", alt: "Towers" },
            { src: "/assets/unboxing.webp", alt: "Unboxing" },
            { src: "/assets/dice.webp", alt: "Dice" },
            { src: "/assets/keno.webp", alt: "Keno" },
            { src: "/assets/plinko.webp", alt: "Plinko" },
            { src: "/assets/cross_the_road.webp", alt: "Cross The Road" },
          ].map((game, index) => (
            <div key={index} className="home-game-tile-container home-disabled">
              <span className="home-coming-soon-badge">Coming soon</span>
              <img src={game.src} alt={game.alt} className="home-game-tile" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Home;
