// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, {  } from 'react';
import './App.css';

import Home from './HomePage/Home';
import Header from './Header/Header'; // Import the new header component
import Forbidden from './ErrorPages/Forbidden';
import NotFound from './ErrorPages/NotFound';
import ServerError from './ErrorPages/ServerError';
import BadRequest from './ErrorPages/BadRequest';
import Register from './Register/Register';
import Login from './Login/Login';
import Limbo from './Limbo/Limbo';
import Coinflip from './Coinflip/Coinflip';
import AdminPage from './AdminPage/AdminPage'

function App() {

  return (
    <Router>
      <div>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/limbo" element={<Limbo />} />
          <Route path="/coinflip" element={<Coinflip />} />
          <Route path="/admin/dashboard" element={<AdminPage />} />

          <Route path="/403" element={<Forbidden />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/500" element={<ServerError />} />
            <Route path="/400" element={<BadRequest />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
      </div>
    </Router>
  );
}

export default App;
