import React, { useState, useEffect, useRef } from 'react';
import './Limbo.css';
import './Keyframes.css';
import './Responsiveness.css';
import { useNavigate } from 'react-router-dom';

function Limbo() {
  const HOUSE_EDGE = 0.04;
  const DEFAULT_CASHOUT = 2.00;

  const calculateLambda = (c, he) => -Math.log((1 - he) / c) / c;

  const [betAmount, setBetAmount] = useState(1.00);
  const [autoCashout, setAutoCashout] = useState(DEFAULT_CASHOUT);
  const [multiplier, setMultiplier] = useState(1.00);
  const [displayMultiplier, setDisplayMultiplier] = useState(1.00);
  const [isWin, setIsWin] = useState(null);
  const [gameRunning, setGameRunning] = useState(false);
  const [betMode, setBetMode] = useState('Manual');
  const [stopLoss, setStopLoss] = useState(10);
  const [stopGain, setStopGain] = useState(50);
  const [autoBetActive, setAutoBetActive] = useState(false);
  const [maxGames, setMaxGames] = useState(Infinity);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [gameHistory, setGameHistory] = useState([]);
  const [animationsEnabled, setAnimationsEnabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [soundVolume, setSoundVolume] = useState(20);
  const [winChance, setWinChance] = useState(0);

  const winSound = useRef(null);
  const navigate = useNavigate();
  const maxBet = 1000.00;
  const maxMultiplier = 10000.00;

  const lambda = calculateLambda(DEFAULT_CASHOUT, HOUSE_EDGE);

  useEffect(() => {
    if (winSound.current) winSound.current.volume = soundVolume / 100;
  }, [soundVolume]);

  const calculateWinChance = (c, lambda) => (Math.exp(-lambda * c) * 100).toFixed(2);

  useEffect(() => {
    const updatedWinChance = calculateWinChance(autoCashout, lambda);
    setWinChance(updatedWinChance);
  }, [autoCashout, lambda]);

  const generateMultiplier = () => {
    const u = Math.random(); // Uniform random number between 0 and 1

    // Adjust the lambda scaling to favor a more balanced distribution of outcomes
    const crashMultiplier = -Math.log(1 - u) / (lambda * 0.8); // Slightly reduced lambda effect for more mid-range multipliers
    const adjustedMultiplier = crashMultiplier * (1 - HOUSE_EDGE);

    // Cap the multiplier at maxMultiplier (10,000)
    return parseFloat(Math.min(adjustedMultiplier, maxMultiplier).toFixed(2));
};


const handlePlaceBet = async () => {
    if (gameRunning) return; // Prevent multiple clicks

    setGameRunning(true);

    try {
        // Deduct balance from the backend
        const response = await fetch('http://localhost:5000/user/placeBet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify({ betAmount }),
        });

        const data = await response.json();
        if (!data.success) {
            alert(data.message || 'Failed to place bet');
            setGameRunning(false);
            return;
        }

        // Emit balance deduction event for animation
        const balanceDeductionEvent = new CustomEvent('balanceUpdate', { detail: { type: 'deduction' } });
        window.dispatchEvent(balanceDeductionEvent);

        // Generate game multiplier
        const randomMultiplier = generateMultiplier();
        setMultiplier(randomMultiplier);

        // Determine win or loss
        const isWin = randomMultiplier >= autoCashout;
        const winnings = isWin ? betAmount * autoCashout : 0;

        // Emit balance win event (if applicable)
        if (isWin) {
            const balanceWinEvent = new CustomEvent('balanceUpdate', { detail: { type: 'win', amount: winnings } });
            window.dispatchEvent(balanceWinEvent);
        }

        // Update displayMultiplier for the UI animation
        setDisplayMultiplier(1.00); // Start animation from 1.00x
        animateMultiplier(randomMultiplier);

        // Update game history
        const gameResult = {
            key: Date.now(),
            multiplier: randomMultiplier,
            win: isWin,
        };
        setGameHistory((prevHistory) => [
            ...prevHistory,
            gameResult,
        ].slice(-25)); // Keep only the last 25 results

        // Play appropriate sound
        if (soundEnabled) {
            isWin ? playWinSound() : playLossSound();
        }

        // Update backend balance for winnings
        if (winnings > 0) {
            const winResponse = await fetch('http://localhost:5000/user/updateBalance', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({ winnings }),
            });

            const winData = await winResponse.json();
            if (!winData.success) {
                console.error('Failed to update balance:', winData.message);
            } else {
                console.log('Balance updated successfully:', winData);
            }
        }
    } catch (error) {
        console.error('Error placing bet:', error);
        alert('An error occurred while placing the bet.');
    } finally {
        // Finalize the game state after animation
        setGameRunning(false);
        setGamesPlayed((prev) => prev + 1);
    }
};


const playWinSound = () => {
  if (winSound.current) {
      winSound.current.pause();
      winSound.current.currentTime = 0;
  }
  winSound.current = new Audio("assets/win-sound.mp3");
  winSound.current.volume = soundVolume / 100;
  winSound.current.play().catch((err) => console.error('Win sound playback failed:', err));
};

const playLossSound = () => {
  if (winSound.current) {
      winSound.current.pause();
      winSound.current.currentTime = 0;
  }
  winSound.current = new Audio("assets/loss-sound.mp3");
  winSound.current.volume = soundVolume / 100;
  winSound.current.play().catch((err) => console.error('Loss sound playback failed:', err));
};


  const animateMultiplier = (targetMultiplier) => {
    let currentMultiplier = Math.max(1, targetMultiplier - 2); // Start 2x below the target, or at 1x minimum
    const interval = setInterval(() => {
        currentMultiplier += 0.1; // Increment gradually
        if (currentMultiplier >= targetMultiplier) {
            currentMultiplier = targetMultiplier; // Snap to target on reaching it
            clearInterval(interval);
            setGameRunning(false);
            setGamesPlayed(prev => prev + 1);
            if (betMode === 'Auto' && autoBetActive) checkAutoBetConditions();
        }
        setDisplayMultiplier(currentMultiplier.toFixed(2)); // Display current multiplier up to 2 decimal places
    }, 20);
};

  const checkAutoBetConditions = () => {
    const lossLimit = (stopLoss / 100) * betAmount;
    const gainLimit = (stopGain / 100) * betAmount;
    if (totalProfit <= -lossLimit || totalProfit >= gainLimit || gamesPlayed >= maxGames) {
      setAutoBetActive(false);
    }
  };

  const toggleBetMode = (mode) => setBetMode(mode);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div>
      <div className="limbo-outer-box">
        <audio ref={winSound} src="assets/win-sound.mp3" />
        <div className="limbo-content-container">
          <div className="limbo-sidebar">
            <div className="limbo-tab-container">
              <button
                className={`limbo-tab ${betMode === 'Manual' ? 'limbo-active' : ''}`}
                onClick={() => toggleBetMode('Manual')}
              >
                Manual
              </button>
              <button
                className={`limbo-tab ${betMode === 'Auto' ? 'limbo-active' : ''}`}
                onClick={() => toggleBetMode('Auto')}
              >
                Auto
              </button>
            </div>

            {betMode === 'Manual' && (
              <div>
                <div className="limbo-input-group">
                  <label>Bet Amount</label>
                  <div className="limbo-input-wrapper">
                    <img src="assets/currency.webp" alt="Currency" className="limbo-currency-icon" />
                    <input
  type="text"
  value={betAmount}
  onChange={(e) => {
    const inputValue = e.target.value;

    // Allow numbers with up to two decimals or empty string
    if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === "") {
      setBetAmount(inputValue); // Allow editing without forcing format
    }
  }}
  onBlur={() => {
    const numericValue = parseFloat(betAmount);

    // Format to two decimals or reset to "1.00" if invalid
    if (!isNaN(numericValue)) {
      setBetAmount(numericValue.toFixed(2));
    } else {
      setBetAmount("1.00");
    }
  }}
  placeholder="Enter your bet"
/>

                    <button onClick={() => setBetAmount(betAmount / 2)} className="limbo-half-btn">1/2</button>
                    <button onClick={() => setBetAmount(Math.min(betAmount * 2, maxBet))} className="limbo-double-btn">2×</button>
                  </div>
                </div>
                <div className="limbo-input-group">
                  <label>Auto Cashout (Target Multiplier)</label>
                  <div className="limbo-input-wrapper">
                    <span className="limbo-icon">X</span>
                    <input
                    type="text"
                    value={autoCashout}
                    onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === "") {
                  setAutoCashout(inputValue); // Allow editing without forcing format
              }
          }}
          onBlur={() => {
            const numericValue = parseFloat(autoCashout);

            if (!isNaN(numericValue)) {
              setAutoCashout(Math.max(1.05, Math.min(numericValue.toFixed(2), maxMultiplier)));
            } else {
              setAutoCashout(DEFAULT_CASHOUT.toFixed(2));
            }
          }}
          placeholder="Enter auto cashout"
          />
        </div>
     </div>
     <button onClick={handlePlaceBet} className="limbo-place-bet" disabled={gameRunning}>Place Bet</button>
     </div>
    )}

            {betMode === 'Auto' && (
              <div>
                <div className="limbo-input-group">
                  <label>Stop on Loss (%)</label>
                  <div className="limbo-input-wrapper">
                    <input
                      type="number"
                      value={stopLoss}
                      onChange={(e) => setStopLoss(e.target.value)}
                      min="1.00"
                      max="1000"
                      step="0.1"
                    />
                    <button onClick={() => setStopLoss(stopLoss / 2)} className="limbo-half-btn">1/2</button>
                    <button onClick={() => setStopLoss(Math.min(stopLoss * 2, 100))} className="limbo-double-btn">2×</button>
                  </div>
                </div>
                <div className="limbo-input-group">
                  <label>Stop on Gain (%)</label>
                  <div className="limbo-input-wrapper">
                    <input
                      type="number"
                      value={stopGain}
                      onChange={(e) => setStopGain(e.target.value)}
                      min="1.00"
                      max="1000"
                      step="0.1"
                    />
                    <button onClick={() => setStopGain(stopGain / 2)} className="limbo-half-btn">1/2</button>
                    <button onClick={() => setStopGain(Math.min(stopGain * 2, 100))} className="limbo-double-btn">2×</button>
                  </div>
                </div>
                <div className="limbo-input-group">
                  <label>Max Games</label>
                  <input
                    type="number"
                    value={maxGames === Infinity ? '' : maxGames}
                    onChange={(e) => setMaxGames(e.target.value ? parseInt(e.target.value, 10) : Infinity)}
                    placeholder="Max bets here"
                    min="1.00"
                    step="1.00"
                  />
                </div>
                <button onClick={() => setAutoBetActive(!autoBetActive)} className="limbo-place-bet">
                  {autoBetActive ? 'Stop Auto Bet' : 'Start Auto Bet'}
                </button>
              </div>
            )}
            <div className="limbo-footer">
              <button onClick={toggleModal} className="limbo-settings-button">⚙️ Settings</button>
            </div>
          </div>

          <div className={`limbo-multiplier-display ${isWin ? 'limbo-win' : isWin === false ? 'limbo-lose' : ''}`}>
            <span className="effect-shine">{parseFloat(displayMultiplier).toFixed(2)}x</span>
            <div className="limbo-win-chance-footer">
              <span className="limbo-win-chance-badge">Win Chance: {winChance}%</span>
            </div>
          </div>
        </div>

        <div className="game-history-container">
          {gameHistory.map((game, index) => (
            <div key={index} className={`limbo-game-history-box ${game.win ? 'win' : 'lose'}`}>
              {game.multiplier.toFixed(2)}x
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="limbo-modal-overlay">
          <div className="limbo-modal limbo-animated-modal">
            <h2>Settings</h2>
            <label>Sound Volume</label>
            <div className="limbo-slider-container">
              <input
                type="range"
                min="0"
                max="100"
                value={soundVolume}
                onChange={(e) => setSoundVolume(e.target.value)}
                className="limbo-custom-slider"
              />
              <span className="limbo-volume-percentage">{soundVolume}%</span>
            </div>
            <div className="limbo-custom-checkbox">
              <input
                type="checkbox"
                id="limbo-animationCheckbox"
                checked={animationsEnabled}
                onChange={() => setAnimationsEnabled(!animationsEnabled)}
              />
              <label htmlFor="limbo-animationCheckbox">Enable Animations</label>
            </div>
            <button onClick={toggleModal} className="limbo-close-button">Close</button>
          </div>
        </div>
      )}

      <LimboGameInfo houseEdge={HOUSE_EDGE * 100} lambda={lambda} />
    </div>
  );
}

function LimboGameInfo({ houseEdge, lambda }) {
  return (
    <div className="limbo-game-info">
      <h3>Limbo</h3>
      <div className="limbo-info-container">
        <div className="limbo-info-left">
          <div className="limbo-info-item">
            <span>House Edge</span>
            <span>{houseEdge}%</span>
          </div>
          <div className="limbo-info-item">
            <span>Max Bet</span>
            <span>1,000.00 <img src="assets/currency.webp" alt="Currency" className="limbo-currency-icon" /></span>
          </div>
          <div className="limbo-info-item">
            <span>Max Win</span>
            <span>10,000.00 <img src="assets/currency.webp" alt="Currency" className="limbo-currency-icon" /></span>
          </div>
          <div className="limbo-info-item">
            <span>Max Multiplier</span>
            <span>10,000.00×</span>
          </div>
        </div>
        <div className="limbo-info-description">
          <p>Limbo is straightforward and simple, yet engaging all the same. This is why it's ideal for all players regardless of experience or expertise, as well as any budget and risk appetite.</p>
          <p>You have the choice to go either really small or make a beeline for bigger wins as high as 1,000.00× your bet.</p>
          <h4>Mathematical Insight:</h4>
          <p>The game is designed with a <strong>{houseEdge}%</strong> house edge, ensuring the casino's profitability over time. The crash multiplier follows an exponential distribution with a rate parameter (λ) of <strong>{lambda.toFixed(3)}</strong>, balancing player excitement with the house advantage.</p>
        </div>
      </div>
    </div>
  );
}

export default Limbo;
