import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './Header.css';
import './Keyframes.css';
import './Responsiveness.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDice, faGift, faDollarSign, faTrophy, faStopwatch, faChevronDown, faPhone, faBars, faWallet } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [balance, setBalance] = useState(0); // Initialize balance as a number
    const [showGamesDropdown, setShowGamesDropdown] = useState(false);
    const [showRewardsDropdown, setShowRewardsDropdown] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [balanceAnimation, setBalanceAnimation] = useState(''); // State for animation class
    const [prevBalance, setPrevBalance] = useState(0); // Track previous balance

    const handleMouseEnterGames = () => setShowGamesDropdown(true);
    const handleMouseLeaveGames = () => setShowGamesDropdown(false);

    const handleMouseEnterRewards = () => setShowRewardsDropdown(true);
    const handleMouseLeaveRewards = () => setShowRewardsDropdown(false);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsLoggedIn(true);

            // Fetch the initial balance
            fetch('http://localhost:5000/user/balance', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch balance');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success) {
                        setBalance(Number(data.balance) || 0); // Ensure balance is a number
                    }
                })
                .catch((error) => console.error('Error fetching balance:', error));

            // Establish WebSocket connection
            const socket = new WebSocket('ws://localhost:5000');

            socket.onopen = () => console.log('WebSocket connection established');

            socket.onmessage = (event) => {
                const { userId, balance: updatedBalance } = JSON.parse(event.data);
                const userFromToken = JSON.parse(atob(token.split('.')[1])).id;

                // Update balance if the message is for the logged-in user
                if (userId === userFromToken) {
                    setBalance(Number(updatedBalance) || 0); // Ensure balance is a number
                }
            };

            socket.onerror = (error) => console.error('WebSocket error:', error);

            socket.onclose = () => console.log('WebSocket connection closed');

            return () => socket.close(); // Cleanup WebSocket on component unmount
        }
    }, []);

    useEffect(() => {
        // Listener for balance updates
        const handleBalanceUpdate = (event) => {
            const { type, amount } = event.detail;
    
            if (type === 'deduction') {
                setBalanceAnimation('balance-decrease');
            } else if (type === 'win') {
                setBalanceAnimation('balance-increase');
            }
    
            // Remove animation class after animation ends
            setTimeout(() => setBalanceAnimation(''), 600);
        };
    
        window.addEventListener('balanceUpdate', handleBalanceUpdate);
    
        return () => {
            window.removeEventListener('balanceUpdate', handleBalanceUpdate);
        };
    }, []);
    

    const handleBalanceUpdate = (newBalance) => {
        setPrevBalance(balance); // Store the current balance
    
        // Trigger appropriate animation based on balance change
        if (newBalance > balance) {
            setBalanceAnimation('balance-increase');
        } else if (newBalance < balance) {
            setBalanceAnimation('balance-decrease');
        }
    
        // Set new balance after a short delay
        setTimeout(() => {
            setBalance(newBalance); // Update balance after animation starts
            setTimeout(() => setBalanceAnimation(''), 600); // Remove animation class after animation ends
        }, 50);
    };
    

    const toggleMobileMenu = () => setIsMobileMenuOpen((prev) => !prev);

    const gameTiles = [
        { src: "/assets/limbo.webp", alt: "Limbo", to: "/limbo", newBadge: true },
        { src: "/assets/coinflip.webp", alt: "Coinflip", to: "/coinflip", newBadge: true },
        { src: "/assets/mines.webp", alt: "Mines", disabled: true },
        { src: "/assets/crash.webp", alt: "Crash", disabled: true },
        { src: "/assets/roulette.webp", alt: "Roulette", disabled: true },
        { src: "/assets/blackjack.webp", alt: "Blackjack", disabled: true },
        { src: "/assets/towers.webp", alt: "Towers", disabled: true },
        { src: "/assets/unboxing.webp", alt: "Unboxing", disabled: true },
        { src: "/assets/dice.webp", alt: "Dice", disabled: true },
        { src: "/assets/keno.webp", alt: "Keno", disabled: true },
        { src: "/assets/plinko.webp", alt: "Plinko", disabled: true },
        { src: "/assets/cross_the_road.webp", alt: "Cross The Road", disabled: true }
    ];

    const renderGameTiles = () => {
        return gameTiles.map((tile, index) => (
            <div key={index} className={`header-tile-container ${tile.disabled ? 'header-disabled' : ''}`}>
                {tile.newBadge && <span className="header-new-badge">New!</span>}
                <Link to={tile.disabled ? '#' : tile.to} className={`header-tile ${tile.disabled ? 'header-disabled' : ''}`}>
                    <img src={tile.src} alt={tile.alt} />
                </Link>
                {tile.disabled && <span className="header-coming-soon-badge">Coming soon</span>}
            </div>
        ));
    };

    return (
        <div className="header-container">
            <header className="header-header">
                <div className="header-logo">
                    <Link to="/">
                        <img src="assets/logo.webp" alt="Casino Logo" />
                    </Link>
                </div>
                <button className="header-mobile-menu-icon" onClick={toggleMobileMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <nav className={`header-nav-menu ${isMobileMenuOpen ? 'header-open' : ''}`}>
                    <div
                        className="header-nav-item"
                        onMouseEnter={handleMouseEnterGames}
                        onMouseLeave={handleMouseLeaveGames}
                    >
                        <FontAwesomeIcon icon={faDice} />
                        <span>Games</span>
                        <FontAwesomeIcon icon={faChevronDown} className="header-dropdown-indicator" />
                        {showGamesDropdown && (
                            <div className="header-dropdown">
                                {renderGameTiles()}
                            </div>
                        )}
                    </div>
                    <div
                        className="header-nav-item"
                        onMouseEnter={handleMouseEnterRewards}
                        onMouseLeave={handleMouseLeaveRewards}
                    >
                        <FontAwesomeIcon icon={faGift} />
                        <span>Rewards</span>
                        <FontAwesomeIcon icon={faChevronDown} className="header-dropdown-indicator" />
                        {showRewardsDropdown && (
                            <div className="header-dropdown2">
                                <div className="header-tile-container">
                                    <span className="header-coming-soon-badge">Coming Soon</span>
                                    <div className="header-tile header-disabled">
                                        <img src="/assets/rakeback.webp" alt="Rakeback" />
                                    </div>
                                </div>
                                <div className="header-tile-container">
                                    <span className="header-coming-soon-badge">Coming Soon</span>
                                    <div className="header-tile header-disabled">
                                        <img src="/assets/promo.webp" alt="Promo Codes" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <Link to="/affiliates" className="header-nav-item">
                        <FontAwesomeIcon icon={faDollarSign} /> Affiliates
                    </Link>
                    <Link to="/leaderboard" className="header-nav-item">
                        <FontAwesomeIcon icon={faTrophy} /> Leaderboard
                    </Link>
                    <Link to="/race" className="header-nav-item">
                        <FontAwesomeIcon icon={faStopwatch} /> Race
                    </Link>
                </nav>

                <div className="header-cta">
                    {isLoggedIn ? (
                        <div className="header-balance-container">
                        <div className={`header-balance ${balanceAnimation}`}>
                            <img src="assets/currency.webp" alt="Currency Icon" className="currency-icon" />
                            <span>{balance.toFixed(2)}</span>
                        </div>
                            <button className="header-wallet-button">
                                <FontAwesomeIcon icon={faWallet} />
                            </button>
                        </div>
                    ) : (
                        <>
                            <Link to="/login" className="header-login-btn">Login</Link>
                            <Link to="/register" className="header-register-btn">Register</Link>
                        </>
                    )}
                </div>
            </header>
        </div>
    );
};

export default Header;
