// Coinflip.js
import React, { useState, useEffect, useRef } from 'react';
import './Coinflip.css';
import './Keyframes.css';
import './Responsiveness.css';
import { useNavigate } from 'react-router-dom';

// Main Coinflip Component
function Coinflip() {
  // State Variables
  const [betAmount, setBetAmount] = useState(1.00);
  const [isWin, setIsWin] = useState(null);
  const [history, setHistory] = useState([]);
  const [gameRunning, setGameRunning] = useState(false);
  const [betMode, setBetMode] = useState('Manual');
  const [stopLoss, setStopLoss] = useState(10); // Percentage
  const [stopGain, setStopGain] = useState(20); // Percentage
  const [autoBetActive, setAutoBetActive] = useState(false);
  const [maxGames, setMaxGames] = useState(Infinity);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [soundVolume, setSoundVolume] = useState(20);
  const [animationsEnabled, setAnimationsEnabled] = useState(true);
  const [selectedSide, setSelectedSide] = useState(null);
  const [isFlipping, setIsFlipping] = useState(false);
  const [coinSide, setCoinSide] = useState('heads');
  const loseSound = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const winSound = useRef(null);
  const navigate = useNavigate();
  const maxBet = 1000.00;

  // Handle Placing a Bet
  const handlePlaceBet = () => {
    setGameRunning(true);
    setSelectedSide(null);
    setIsWin(null); // Reset the win state for each new game
  };

  // Handle Choosing a Side (Heads or Tails)
  const handleChooseSide = (side) => {
      setSelectedSide(side);
      setIsFlipping(animationsEnabled);

      // Simulate Coin Flipping Animation
      let randomSideInterval = setInterval(() => {
          setCoinSide(Math.random() > 0.5 ? 'heads' : 'tails');
      }, 100);

      setTimeout(() => {
          clearInterval(randomSideInterval);

          const isHeads = Math.random() > 0.5;
          const won = (isHeads && side === 'heads') || (!isHeads && side === 'tails');
          setIsWin(won);

          // Play win sound if user won
          if (won) {
              winSound.current.volume = soundVolume / 100;
              winSound.current.currentTime = 0;
              winSound.current.play();
              setTotalProfit(prev => prev + betAmount * 0.96);
          } else {
              setTotalProfit(prev => prev - betAmount);
          }

          setCoinSide(isHeads ? 'heads' : 'tails');
          setHistory(prevHistory => [...prevHistory, { won, outcome: isHeads ? 'heads' : 'tails' }]);

          setTimeout(() => {
              setIsFlipping(false);
              setGameRunning(false); // Allow for a new game
              setGamesPlayed(prev => prev + 1);
              if (betMode === 'Auto' && autoBetActive) checkAutoBetConditions();
          }, 500);
      }, 1000);
  };

  // Check Auto Betting Conditions
  const checkAutoBetConditions = () => {
    const lossLimit = (stopLoss / 100) * betAmount;
    const gainLimit = (stopGain / 100) * betAmount;
    if (totalProfit <= -lossLimit || totalProfit >= gainLimit || gamesPlayed >= maxGames) {
      setAutoBetActive(false);
    } else {
      handlePlaceBet();
    }
  };

  // Toggle Betting Mode (Manual/Auto)
  const toggleBetMode = (mode) => setBetMode(mode);

  // Toggle Settings Modal
  const toggleSettingsModal = () => setIsSettingsModalOpen(!isSettingsModalOpen);

  return (
    <div>
      <div className="coinflip-outer-box">
        {/* Audio Element for Win Sound */}
        <audio ref={winSound} src="assets/win-sound.mp3" />
        <audio ref={loseSound} src="assets/loss-sound.mp3" />

        <div className="coinflip-content-container">
          {/* Sidebar */}
          <div className="coinflip-sidebar">
            {/* Betting Mode Tabs */}
            <div className="coinflip-tab-container">
              {['Manual', 'Auto'].map(mode => (
                <button 
                  key={mode}
                  className={`coinflip-tab ${betMode === mode ? 'coinflip-active' : ''}`}
                  onClick={() => toggleBetMode(mode)}
                >
                  {mode}
                </button>
              ))}
            </div>

            {/* Manual Betting Mode */}
            {betMode === 'Manual' && (
              <div>
                {/* Bet Amount Input */}
                <div className="coinflip-input-group">
                  <label>Bet Amount</label>
                  <div className="coinflip-input-wrapper">
                    <img src="assets/currency.webp" alt="Currency" className="coinflip-currency-icon" />
                    <input
  type="text" // Use text to gain full control over formatting
  value={betAmount}
  onChange={(e) => {
    const inputValue = e.target.value;

    // Allow only valid numeric input with up to two decimal places
    if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
      setBetAmount(inputValue); // Temporarily set valid input as a string
    }
  }}
  onBlur={(e) => {
    const numericValue = parseFloat(e.target.value);

    // On blur, ensure value is properly formatted and valid
    if (!isNaN(numericValue)) {
      setBetAmount(numericValue.toFixed(2)); // Format to two decimals
    } else {
      setBetAmount("1.00"); // Reset to "1.00" for invalid input
    }
  }}
  placeholder="Enter your bet"
/>

                    <button onClick={() => setBetAmount(prev => Math.max(1, prev / 2))} className="coinflip-half-btn">1/2</button>
                    <button onClick={() => setBetAmount(prev => Math.min(prev * 2, maxBet))} className="coinflip-double-btn">2×</button>
                  </div>
                </div>

                {/* Place Bet Button */}
                <button onClick={handlePlaceBet} className="coinflip-place-bet" disabled={gameRunning}>
                  Place Bet
                </button>

                {/* Choose Side Buttons */}
                {gameRunning && (
                  <div className="coinflip-choose-side-container">
                    {['heads', 'tails'].map(side => (
                      <button 
                        key={side} 
                        onClick={() => handleChooseSide(side)} 
                        className="coinflip-side-btn" 
                        disabled={selectedSide !== null}
                      >
                        <img src={`assets/${side}.webp`} alt={side} />
                      </button>
                    ))}
                  </div>
                )}
              </div>
            )}

            {/* Auto Betting Mode */}
            {betMode === 'Auto' && (
              <div>
                {/* Auto Betting Settings */}
                {[
                  { label: 'Stop on Loss (%)', value: stopLoss, onChange: setStopLoss },
                  { label: 'Stop on Gain (%)', value: stopGain, onChange: setStopGain },
                  { label: 'Max Games', value: maxGames, onChange: val => setMaxGames(val ? parseInt(val, 10) : Infinity) },
                ].map(({ label, value, onChange }, i) => (
                  <div key={i} className="coinflip-input-group">
                    <label>{label}</label>
                    <div className="coinflip-input-wrapper">
                      <input
                        type="number"
                        value={value}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (label === 'Max Games') {
                            onChange(newValue ? parseInt(newValue, 10) : Infinity);
                          } else {
                            onChange(newValue ? parseFloat(newValue) : 0);
                          }
                        }}
                        min="1.00"
                        max={label === 'Max Games' ? 1000 : 100}
                        step="1.00"
                      />
                      <button 
                        onClick={() => {
                          const newVal = label === 'Max Games' ? Math.max(0, value / 2) : value / 2;
                          onChange(newVal);
                        }} 
                        className="coinflip-half-btn"
                      >
                        1/2
                      </button>
                      <button 
                        onClick={() => {
                          const newVal = label === 'Max Games' ? Math.min(value * 2, 1000) : Math.min(value * 2, 100);
                          onChange(newVal);
                        }} 
                        className="coinflip-double-btn"
                      >
                        2×
                      </button>
                    </div>
                  </div>
                ))}

                {/* Start/Stop Auto Bet Button */}
                <button 
                  onClick={() => {
                    if (!autoBetActive) {
                      setAutoBetActive(true);
                      handlePlaceBet();
                    } else {
                      setAutoBetActive(false);
                    }
                  }} 
                  className="coinflip-place-bet"
                >
                  {autoBetActive ? 'Stop Auto Bet' : 'Start Auto Bet'}
                </button>
              </div>
            )}

            {/* Sidebar Footer with Settings Button */}
            <div className="coinflip-footer">
              <button onClick={toggleSettingsModal} className="coinflip-settings-button">⚙️ Settings</button>
            </div>
          </div>

          {/* Game Display Area */}
          <div className={`coinflip-multiplier-display ${isFlipping ? 'coinflip-flipping' : ''} ${isWin === true ? 'coinflip-win' : isWin === false ? 'coinflip-lose' : ''}`}>
            <div className="shine-wrapper">
              <img src={`assets/${coinSide}.webp`} alt="Game Result" className="coinflip-game-image" />
            </div>
          </div>
        </div>

        {/* History Footer */}
        <div className="coinflip-footer">
          <div className="coinflip-history">
            {history.map((result, index) => (
              <div key={index} className={`coinflip-game-history-box ${result.won ? 'win' : 'lose'}`}>
                <img src={`assets/${result.outcome}.webp`} alt={result.outcome} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Settings Modal */}
      {isSettingsModalOpen && (
        <div className="coinflip-modal-overlay" onClick={toggleSettingsModal}>
          <div className="coinflip-modal coinflip-animated-modal" onClick={(e) => e.stopPropagation()}>
            <h2>Settings</h2>
            {/* Sound Volume Control */}
            <label>Sound Volume</label>
            <div className="coinflip-slider-container">
              <input
                type="range"
                min="0"
                max="100"
                value={soundVolume}
                onChange={(e) => setSoundVolume(parseInt(e.target.value, 10))}
                className="coinflip-custom-slider"
              />
              <span className="coinflip-volume-percentage">{soundVolume}%</span>
            </div>

            {/* Animations Toggle */}
            <div className="coinflip-custom-checkbox">
              <input
                type="checkbox"
                id="coinflip-animationCheckbox"
                checked={animationsEnabled}
                onChange={() => setAnimationsEnabled(!animationsEnabled)}
              />
              <label htmlFor="coinflip-animationCheckbox">Enable Animations</label>
            </div>

            {/* Close Button */}
            <button onClick={toggleSettingsModal} className="coinflip-close-button">Close</button>
          </div>
        </div>
      )}

      {/* Game Information Section */}
      <CoinflipGameInfo />
    </div>
  );
}

// CoinflipGameInfo Component
function CoinflipGameInfo() {
  return (
    <div className="coinflip-game-info">
      <h3>Coinflip</h3>
      <div className="coinflip-info-container">
        {/* Left Side Information */}
        <div className="coinflip-info-left">
          {[
            { label: 'House Edge', value: '4%' },
            { label: 'Max Bet', value: '1,000.00' },
            { label: 'Max Win', value: '10,000.00' },
            { label: 'Max Multiplier', value: '1,000.00X' },
          ].map((item, i) => (
            <div key={i} className="coinflip-info-item">
              <span>{item.label}</span>
              <span>{item.value} <img src="assets/currency.webp" alt="Currency" className="coinflip-currency-icon" /></span>
            </div>
          ))}
        </div>

        {/* Right Side Description */}
        <div className="coinflip-info-description">
          <p>
            Coinflip is straightforward and simple, yet engaging all the same. This is why it's ideal for all players regardless of experience or expertise, as well as any budget and risk appetite.
          </p>
          <p>
            You have the choice to go either really small or make a beeline for bigger wins as high as <strong>1,000.00×</strong> your bet.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Coinflip;
