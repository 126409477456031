import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';
import './Keyframes.css';
import './Responsiveness.css';

const Login = () => {
  const [activeTab, setActiveTab] = useState('email');
  const [formData, setFormData] = useState({
    emailOrUsername: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: value ? '' : prevErrors[name]
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.emailOrUsername) newErrors.emailOrUsername = "Email or username is required";
    if (!formData.password) newErrors.password = "Password is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    fetch('http://localhost:5000/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    .then(response => {
        if (!response.ok) {
            if (response.status === 403) {
                throw new Error('You have been blacklisted');
            } else if (response.status === 401) {
                throw new Error('Invalid credentials. Please check your username or password.');
            } else {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }
        }
        return response.json();
    })
    .then(data => {
        if (data.accessToken) {
            localStorage.setItem('authToken', data.accessToken);
            return fetch('http://localhost:5000/user/balance', {
                headers: {
                    Authorization: `Bearer ${data.accessToken}`,
                },
            });
        } else {
            throw new Error('Login failed: No access token provided');
        }
    })
    .then(response => response.json())
    .then(balanceData => {
        if (balanceData.success) {
            localStorage.setItem('userBalance', balanceData.balance); // Save balance locally
            navigate('/'); // Redirect to home
            window.location.reload(); // Ensure the Header reloads updated data
        }
    })
    .catch(error => {
        console.error('Error:', error);
        setErrors({ form: error.message });
    });
};


  return (
    <div className="login-page-container">
      <form className="login-page-form" onSubmit={handleSubmit}>
        <h1 className="login-page-title">Welcome Back</h1>

        <div className="tab-menu">
          <div
            className={`tab-item ${activeTab === 'email' ? 'active' : ''}`}
            onClick={() => setActiveTab('email')}
          >
            Email
          </div>
          <div
            className={`tab-item ${activeTab === 'username' ? 'active' : ''}`}
            onClick={() => setActiveTab('username')}
          >
            Username
          </div>
        </div>

        <div className="login-input-group">
          <label htmlFor="emailOrUsername">{activeTab === 'email' ? 'Email' : 'Username'}</label>
          <input
            type={activeTab === 'email' ? 'email' : 'text'}
            id="emailOrUsername"
            name="emailOrUsername"
            value={formData.emailOrUsername}
            onChange={handleChange}
            required
            placeholder={activeTab === 'email' ? 'Enter your email' : 'Enter your username'}
          />
          {errors.emailOrUsername && <span className="error-message">{errors.emailOrUsername}</span>}
        </div>

        <div className="login-input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          {errors.password && <span className="error-message">{errors.password}</span>}
        </div>

        <button type="submit" className="login-page-btn">Login</button>
        {errors.form && <div className="error-message form-error">{errors.form}</div>}

        <div className="register-link-container">
          <span className="register-text">No Account? </span>
          <Link to="/register" className="register-link">Register now!</Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
