import React, { useState, useMemo } from 'react';
import './AdminPage.css';
import { FaCoins, FaUsers, FaBan, FaCog, FaClipboardList } from 'react-icons/fa';

const userDataInitial = [
  {
    id: 1,
    username: 'Gargamel',
    roles: 'Owner',
    permission_level: 999,
    nickname: 'Johnny',
    email: 'egijeorgoiiojji@gmail.com',
    is_verified: 0,
    date_of_birth: '2001-04-29',
    balance: 0.00,
    total_deposit: 0.00,
    total_withdrawn: 0.00,
    total_bets: 0,
    total_bet_wins: 0,
    total_bet_losses: 0,
    blacklisted: 0,
    blacklist_reason: '',
    blacklist_expiry: ''
  },
  // Add more user objects as needed
];

const activityLogs = [
  "User 'Gargamel' logged in.",
  "User 'Gargamel' updated profile settings.",
  "User 'Gargamel' added to blacklist.",
  "User 'Gargamel' removed from blacklist.",
  // Add more logs as needed
];

function AdminPage() {
  const [activeTab, setActiveTab] = useState('users');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [blacklistDuration, setBlacklistDuration] = useState('');
  const [customDuration, setCustomDuration] = useState('');
  const [userData, setUserData] = useState(userDataInitial);

  const filteredUsers = useMemo(() => userData.filter(user => 
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  ), [searchQuery, userData]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setActiveTab('logs');
    setSearchQuery('');
  };

  const updateUserData = (userId, field, value) => {
    setUserData(prevData => prevData.map(user => 
      user.id === userId ? { ...user, [field]: value } : user
    ));
  };

  return (
    <div className="adminpage-container">
      <div className="adminpage-sidebar">
        <button onClick={() => setActiveTab('users')} className={`adminpage-sidebar-button ${activeTab === 'users' ? 'active' : ''}`}>
          <FaUsers style={{ marginRight: '8px' }} /> Users
        </button>
        <button onClick={() => setActiveTab('blacklist')} className={`adminpage-sidebar-button ${activeTab === 'blacklist' ? 'active' : ''}`}>
          <FaBan style={{ marginRight: '8px' }} /> Blacklist
        </button>
        <button onClick={() => setActiveTab('financials')} className={`adminpage-sidebar-button ${activeTab === 'financials' ? 'active' : ''}`}>
          <FaCoins style={{ marginRight: '8px' }} /> Balance
        </button>
        <button onClick={() => setActiveTab('logs')} className={`adminpage-sidebar-button ${activeTab === 'logs' ? 'active' : ''}`}>
          <FaClipboardList style={{ marginRight: '8px' }} /> Logs
        </button>
        <button onClick={() => setActiveTab('settings')} className={`adminpage-sidebar-button ${activeTab === 'settings' ? 'active' : ''}`}>
          <FaCog style={{ marginRight: '8px' }} /> Settings
        </button>
      </div>

      <div className="adminpage-content">
        {activeTab === 'users' && (
          <div className="adminpage-tab-content">
            <h2 className="adminpage-tab-title">Registered Users</h2>
            
            {/* Search bar added here */}
            <label className="adminpage-label" htmlFor="searchQuery">Search User</label>
            <input 
              id="searchQuery"
              type="text" 
              placeholder="Search for a user..." 
              value={searchQuery} 
              onChange={(e) => setSearchQuery(e.target.value)}
              className="adminpage-search-bar"
            />

            <table className="adminpage-users-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Nickname</th>
                  <th>Roles</th>
                  <th>Email Verified</th>
                  <th>Blacklisted</th>
                  <th>Blacklist Reason</th>
                  <th>Blacklist Expiry</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map(user => (
                  <tr key={user.id} onClick={() => handleUserSelect(user)} className="adminpage-table-row">
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.nickname}</td>
                    <td>{user.roles}</td>
                    <td>{user.verified? 'Yes' : 'No'} </td>
                    <td>{user.blacklisted ? 'Yes' : 'No'}</td>
                    <td>{user.blacklist_reason || '-'}</td>
                    <td>{user.blacklist_expiry ? new Date(user.blacklist_expiry).toLocaleDateString() : '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredUsers.length === 0 && <p>No users found.</p>}
          </div>
        )}

        {activeTab === 'blacklist' && selectedUser && (
          <div className="adminpage-tab-content">
            <h2 className="adminpage-tab-title">Blacklist User</h2>
            <label className="adminpage-label" htmlFor="blacklistReason">Reason for Blacklist</label>
            <input 
              type="text" 
              id="blacklistReason" 
              placeholder="Enter reason for blacklist" 
              className="adminpage-input" 
              value={selectedUser.blacklist_reason}
              onChange={(e) => updateUserData(selectedUser.id, 'blacklist_reason', e.target.value)}
            />
            <label className="adminpage-label" htmlFor="blacklistDuration">Blacklist Duration</label>
            <select 
              id="blacklistDuration"
              className="adminpage-select"
              value={blacklistDuration}
              onChange={(e) => setBlacklistDuration(e.target.value)}
            >
              <option value="">Select duration</option>
              <option value="1 day">1 Day</option>
              <option value="1 week">1 Week</option>
              <option value="1 month">1 Month</option>
              <option value="6 months">6 Months</option>
              <option value="1 year">1 Year</option>
              <option value="custom">Custom Duration</option>
            </select>
            {blacklistDuration === 'custom' && (
              <>
                <label className="adminpage-label" htmlFor="customDuration">Custom Duration (days)</label>
                <input
                  id="customDuration"
                  type="number"
                  placeholder="Enter number of days"
                  value={customDuration}
                  onChange={(e) => setCustomDuration(e.target.value)}
                  className="adminpage-input"
                />
              </>
            )}
            <button 
              className="adminpage-button adminpage-save-button" 
              onClick={() => alert('User blacklisted')}
            >
              Blacklist User
            </button>
          </div>
        )}

        {activeTab === 'settings' && selectedUser && (
          <div className="adminpage-tab-content">
            <h2 className="adminpage-tab-title">Settings</h2>
            <label className="adminpage-label" htmlFor="username">Username</label>
            <input 
              type="text" 
              id="username" 
              placeholder="Username" 
              defaultValue={selectedUser.username} 
              className="adminpage-input" 
              onChange={(e) => updateUserData(selectedUser.id, 'username', e.target.value)}
            />
            <label className="adminpage-label" htmlFor="nickname">Nickname</label>
            <input 
              type="text" 
              id="nickname" 
              placeholder="Nickname" 
              defaultValue={selectedUser.nickname} 
              className="adminpage-input" 
              onChange={(e) => updateUserData(selectedUser.id, 'nickname', e.target.value)}
            />
            <label className="adminpage-label" htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              placeholder="Email" 
              defaultValue={selectedUser.email} 
              className="adminpage-input" 
              onChange={(e) => updateUserData(selectedUser.id, 'email', e.target.value)}
            />
            <label className="adminpage-label" htmlFor="permissionLevel">Permission Level</label>
            <input 
              type="number" 
              id="permissionLevel" 
              placeholder="Permission Level" 
              defaultValue={selectedUser.permission_level} 
              className="adminpage-input" 
              onChange={(e) => updateUserData(selectedUser.id, 'permission_level', parseInt(e.target.value, 10) || 0)}
            />
            <label className="adminpage-checkbox-container">
              <input 
                type="checkbox" 
                defaultChecked={selectedUser.is_verified} 
                className="adminpage-checkbox" 
                onChange={(e) => updateUserData(selectedUser.id, 'is_verified', e.target.checked ? 1 : 0)}
              />
              Verified
            </label>
            <button 
              className="adminpage-button adminpage-save-button" 
              onClick={() => alert('User settings updated')}
            >
              Save Changes
            </button>
          </div>
        )}

        {activeTab === 'financials' && selectedUser && (
          <div className="adminpage-tab-content">
            <h2 className="adminpage-tab-title">Financials</h2>
            {['balance', 'total_deposit', 'total_withdrawn', 'total_bets', 'total_bet_wins', 'total_bet_losses'].map(field => (
              <label key={field} className="adminpage-label" htmlFor={field}>
                {field.replace(/_/g, ' ')}
                <input 
                  type="number" 
                  id={field} 
                  placeholder={field.replace(/_/g, ' ')} 
                  defaultValue={selectedUser[field]} 
                  className="adminpage-input" 
                  onChange={(e) => updateUserData(selectedUser.id, field, parseFloat(e.target.value) || 0)}
                />
              </label>
            ))}
            <button 
              className="adminpage-button adminpage-save-button" 
              onClick={() => alert('Financial information updated')}
            >
              Save Changes
            </button>
          </div>
        )}

        {activeTab === 'logs' && (
          <div className="adminpage-tab-content">
            <h2 className="adminpage-tab-title">Activity Logs</h2>
            <div className="adminpage-logs-container">
              {activityLogs.map((log, index) => (
                <p key={index} className="adminpage-log-item">{log}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminPage;
